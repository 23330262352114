import { Component } from "react";
import { Grid } from "@mui/material";

class KeySkillIcon extends Component {
  render() {
    var iconinfo = this.props.icon;

    return (
      <Grid item xs={4} sm={2}>
        <a href={iconinfo.url} target="_blank" rel="noreferrer">
          <img alt={iconinfo.alt} src={iconinfo.icon} title={iconinfo.alt} />
        </a>
      </Grid>
    );
  }
}

export default KeySkillIcon;
