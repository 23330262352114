import {
  Card,
  CardMedia,
  Typography,
  CardHeader,
  CardContent,
  Divider,
  List,
  Box,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import EducationLine from "./EducationLine";

const educationList = [
  {
    institution: "University of Newcastle (Australia)",
    daterange: "Jan 2018 to Dec 2022",
    course: "Bachelor of Applied Information Technology (BAppIT)",
    logo: "/images/logo_uon.png",
  },
];

const trainingList = [
  {
    institution: "Frontend Masters",
    daterange: "",
    url: "https://frontendmasters.com/courses/complete-react-v8/",
    course: "Complete Intro to React, v8",
    logo: "/images/logo_fm.png",
  },
  {
    institution: "Frontend Masters",
    daterange: "",
    url: "https://frontendmasters.com/courses/intermediate-react-v5/",
    course: "Intermediate React, v5",
    logo: "/images/logo_fm.png",
  },
  {
    institution: "Udemy",
    daterange: "",
    url: "https://www.udemy.com/course/nodejs-the-complete-guide/",
    course: "NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)",
    logo: "/images/logo_udemy.png",
  },
  {
    institution: "Udemy",
    daterange: "",
    url: "https://www.udemy.com/course/nodejs-the-complete-guide/",
    course: "Complete React Developer in 2023 (w/ Redux, Hooks, GraphQL)",
    logo: "/images/logo_udemy.png",
  },
  {
    institution: "Udemy",
    daterange: "",
    url: "https://www.udemy.com/course/css-the-complete-guide-incl-flexbox-grid-sass/",
    course: "CSS - The Complete Guide 2023 (incl. Flexbox, Grid & Sass)",
    logo: "/images/logo_udemy.png",
  },
  {
    institution: "Udemy",
    daterange: "",
    url: "https://www.udemy.com/course/stripe-course/",
    course: "Stripe In Practice",
    logo: "/images/logo_udemy.png",
  },
];

const EducationCard = () => {
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Education and Training"
        subheader="Relevant Education"
        avatar={
          <Avatar
            src="/images/icon_education.png"
            sx={{ bgcolor: "avatar.main", width: 64, height: 64 }}
          />
        }
      />

      <CardMedia
        component="img"
        image="/images/banner_education.png"
        alt="Nathan Aichinger"
      />

      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="span"
          sx={{ marginBottom: "0px" }}
        >
          University
        </Typography>

        <List>
          {educationList.map((position, index) => (
            <Box key={`wl_${index}`}>
              {index !== 0 ? <Divider variant="inset" component="li" /> : null}
              <EducationLine position={position} />
            </Box>
          ))}
        </List>

        <Typography
          gutterBottom
          variant="h6"
          component="span"
          sx={{ marginBottom: "0px" }}
        >
          Additional Training
        </Typography>
        <List>
          {trainingList.map((position, index) => (
            <Box key={`wl_${index}`}>
              {index !== 0 ? <Divider variant="inset" component="li" /> : null}
              <EducationLine position={position} />
            </Box>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default EducationCard;
