import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardMedia,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import KeySkillIcon from "./KeySkillIcon";
import KeySkillIconHalf from "./KeySkillIconHalf";

const languageIcons = [
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    alt: "JavaScript",
    url: "https://www.javascript.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-original.svg",
    alt: "TypeScript",
    url: "https://www.typescriptlang.org/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
    alt: "React",
    url: "https://reactjs.org/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
    alt: "Node.js",
    url: "https://nodejs.org/en/",
  },
];

const styleLibraryIcons = [
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
    alt: "Tailwind",
    url: "https://tailwindcss.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/materialui/materialui-original.svg",
    alt: "Materials UI",
    url: "https://mui.com/",
  },
];

const additionalIcons = [
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg",
    alt: "html5",
    url: "https://developer.mozilla.org/en-US/docs/Glossary/HTML5",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg",
    alt: "css3",
    url: "https://developer.mozilla.org/en-US/docs/Web/CSS",
  },
];

const dataStorageIcons = [
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
    alt: "MySQL",
    url: "https://www.mysql.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg",
    alt: "MongoDB",
    url: "https://www.mongodb.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/microsoftsqlserver/microsoftsqlserver-plain.svg",
    alt: "MS SQL",
    url: "https://www.microsoft.com/en-us/sql-server",
  },
];

const techIcons = [
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/npm/npm-original-wordmark.svg",
    alt: "NPM",
    url: "https://www.npmjs.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg",
    alt: "VS Code",
    url: "https://code.visualstudio.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
    alt: "Git",
    url: "https://github.com/",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original.svg",
    alt: "Jira",
    url: "https://www.atlassian.com/software/jira",
  },
  {
    icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/confluence/confluence-original.svg",
    alt: "Confluence",
    url: "https://www.atlassian.com/software/confluence",
  },
];

const KeySkillsCard = () => {
  return (
    <Card mb={5} style={{ width: "100%"}}>
      <CardHeader
        title="Technology and Key Skills"
        subheader="Job relevant backgrounds"
        avatar={
          <Avatar
            src="/images/icon_technology.png"
            sx={{ bgcolor: "avatar.main", width: 64, height: 64 }}
          />
        }
      />

      <CardMedia
        component="img"
        image="/images/banner_skill.png"
        alt="Nathan Aichinger"
      />

      <CardContent>
        <Grid container spacing={2} justifyContent="center" paddingBottom={2}>
          <Grid item xs={12} sm={6} justifyContent="center">
            <center>
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                align="center"
              >
                Programming Langauages
              </Typography>
            </center>
            <Grid container spacing={2} justifyContent="center">
                {languageIcons.map((icon, index) => (
                  <KeySkillIcon key={`si_${index}`} icon={icon} />
                ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <center>
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                align="center"
              >
                Additional Languages
              </Typography>
            </center>
            <Grid container spacing={2} justifyContent="center">
              {additionalIcons.map((icon, index) => (
                <KeySkillIcon key={`si_${index}`} icon={icon} />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          spacing={2}
          justifyContent="center"
          paddingTop={2}
          paddingBottom={2}
        >
          <Grid item xs={12} sm={6} justifyContent="center">
            <center>
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                align="center"
              >
                Data Storage
              </Typography>
            </center>
            <Grid container spacing={2} justifyContent="center">
              {dataStorageIcons.map((icon, index) => (
                <KeySkillIcon key={`si_${index}`} icon={icon} />
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} justifyContent="center">
            <center>
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                align="center"
              >
                Style Libraries
              </Typography>
            </center>
            <Grid container spacing={2} justifyContent="center">
              {styleLibraryIcons.map((icon, index) => (
                <KeySkillIcon key={`si_${index}`} icon={icon} />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Grid container spacing={2} justifyContent="center" paddingTop={2}>
          <Grid item xs={12} s={12} justifyContent="center">
            <center>
              <Typography
                gutterBottom
                variant="h6"
                component="span"
                align="center"
              >
                IDE and Extra Tools
              </Typography>
            </center>
            <Grid container spacing={2} justifyContent="center">
              {techIcons.map((icon, index) => (
                <KeySkillIconHalf key={`si_${index}`} icon={icon} />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default KeySkillsCard;
