import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const KeyProjectsCard = () => {
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Key Projects"
        subheader="Interesting prior works"
        avatar={
          <Avatar
            src="/images/icon_key.png"
            sx={{ bgcolor: "avatar.main", width: 64, height: 64 }}
          />
        }
      />

      <CardContent>
        <List>
          <ListItemButton
            alignItems="flex-start"
            href="https://www.pallettracker.io/"
          >
            <ListItemAvatar>
              <Avatar
                alt="Education"
                sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
                src="/images/keyproject_pallettracker.png"
              ></Avatar>
            </ListItemAvatar>

            <ListItemText
              primary="Pallet Tracker"
              secondary="An ecosystem that drives Collector efficiency and generates a long term, sustainable increase in the number of assets returned."
            />
          </ListItemButton>
        </List>
      </CardContent>
    </Card>
  );
};

export default KeyProjectsCard;
