import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container, CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Splash from "./Splash";
import Demos from "./Demos";
import NotFound from "./NotFound";
import { useState } from "react";

import AppBar from "./component/NavBar";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./App.css";
import Prototypes from "./Prototypes";
import MenuDarwer from "./component/MenuDrawer";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2e7d32",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#d9d5d4",
    },
    avatar: {
      main: "#ecfce1",
    },
  },
});

function App() {
  const [isDrawerOpen, setIsDrawOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={{
          body: { marginTop: 75 },
        }}
      />
      <Container maxWidth="xl">
        <BrowserRouter>
          <AppBar onOpenFunc={openDrawer} />

          <MenuDarwer isOpen={isDrawerOpen} onCloseFunc={closeDrawer} />
          <Routes>
            <Route path="/demos/" element={<Demos />} />
            <Route path="/proto/" element={<Prototypes />} />
            <Route path="/" element={<Splash />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
