import { Component, Fragment } from "react";
import {
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
  Box,
} from "@mui/material";

class WorkHistoryLine extends Component {
  render() {
    var posinfo = this.props.position;
    return (
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar
            alt="Travis Howard"
            sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
          >
            {posinfo.avatartxt}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={posinfo.position}
          secondary={
            <Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {posinfo.employer} ({posinfo.locationbasic})
              </Typography>
              <br />
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                {posinfo.daterange}
              </Typography>

              <br />
              <Box component="span">
                {posinfo.extendedBlurb.map((position, index) => (
                  <Box
                    component="span"
                    className="sub-point checkmark"
                    key={index}
                  >
                    {` - ${position}`}
                    <br />
                  </Box>
                ))}
              </Box>
            </Fragment>
          }
        ></ListItemText>
      </ListItem>
    );
  }
}

export default WorkHistoryLine;
