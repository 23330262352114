import {
  Card,
  CardHeader,
  CardContent,
  List,
  Divider,
  CardMedia,
  Box,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import WorkHistoryLine from "./WorkHistoryLine";

const positionList = [
  {
    employer: "Self Employement",
    location: "Linz, Oberosterreich (Austria)",
    locationbasic: "Austria",
    position: "Systems Analyst / Programmer",
    daterange: "Jan 2018 to Dec 2022",
    avatartxt: "SE",
    extendedBlurb: [
      "Provided systems analysis and development services for small-to-medium firms.",
      "Worked with multiple firms, across multiple industries.",
      "Asset reclamation, Satellite Telecommunications, managing a fleet of trains and associated logistics.",
    ],
  },
  {
    employer: "MD Brick Pty Ltd",
    location: "Gosford, NSW (Australia)",
    locationbasic: "Australia",
    position: "Systems Analyst / Programmer",
    daterange: "Dec 2015 to Dec 2017",
    avatartxt: "MD",
    extendedBlurb: [
      "Conceptualise, develop and launch of the Pallet Tracker ecosystem.",
      "Oversaw IT infrastructure for a growing business in the building supplies sector.",
    ],
  },
  {
    employer: "Design 9 Pty Ltd",
    location: "Gosford, NSW (Australia)",
    locationbasic: "Australia",
    position: "Systems Analyst / Programmer",
    daterange: "Sep 2012 to Dec 2017",
    avatartxt: "D9",
    extendedBlurb: [
      "Developed and tested a custom built external web solution for logging and reporting data from remote assets.",
      "This allowed for small-scale customers to utilize offered satellite services.",
      "Designed, developed and tested a custom internal web tool for managing call data records, and perform billing and accounting tasks.",
    ],
  },
  {
    employer: "Custom Fluidpower Pty Ltd",
    location: "Newcastle, NSW (Australia)",
    locationbasic: "Australia",
    position: "Systems Analyst / Programmer / IT Support",
    daterange: "Sep 2012 to Dec 2017",
    avatartxt: "CF",
    extendedBlurb: [
      "Ongoing management and new feature development of the company’s original Enterprise Resource Planning (ERP) solution, Dynamics AX.",
      " Key stakeholder, overseeing all technical aspects of the ERP migration, from Dynamics AX to NetSuite.",
      "Outside the scope of the ERP, provided additional support of ancillary software development needs.",
    ],
  },
];

const WorkHistoryCard = () => {
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Employment History"
        subheader="Prior relevant experience"
        avatar={
          <Avatar
            src="/images/icon_employment.png"
            sx={{ bgcolor: "avatar.main", width: 64, height: 64 }}
          />
        }
      />

      <CardMedia
        component="img"
        image="/images/banner_workhistory.png"
        alt="Nathan Aichinger"
      />

      <CardContent>
        <List>
          {positionList.map((position, index) => (
            <Box key={`wl_${index}`}>
              {index !== 0 ? <Divider variant="inset" component="li" /> : null}
              <WorkHistoryLine position={position} />
            </Box>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default WorkHistoryCard;
