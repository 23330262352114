import { useState } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { ThemeProvider, createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";

const theme = createTheme({
  typography: {
    h2: {
      fontSize: 30,
      marginBottom: 0,
    },
  },
  palette: {
    primary: {
      main: orange[500],
    },
  },
});

function CheckboxExample() {
  const [checked, setChecked] = useState(true);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          icon={<SaveIcon />}
          checkedIcon={<DeleteIcon />}
          color="secondary"
          inputProps={{
            "aria-label": "secondary checkbox",
          }}
        />
      }
      label="Jacks Lament"
    />
  );
}

const Demos = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" style={{ border: "1px solid black" }}>
        <Typography variant="h2" component="div">
          Welcome to MUI
        </Typography>

        <Typography variant="subtitle1">
          Learn how to use Material UI
        </Typography>

        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ border: "1px solid black" }}
        >
          <Grid item xs={3} sm={6}>
            <Paper style={{ height: 75, width: "100%" }}>A</Paper>
          </Grid>

          <Grid item xs={3} sm={6}>
            <Paper style={{ height: 75, width: "100%" }} />
          </Grid>

          <Grid item xs={3} sm={6}>
            <Paper style={{ height: 75, width: "100%" }} />
          </Grid>
        </Grid>

        <div className="demos-page">
          <h2>Demos</h2>

          <div>
            <h3>Buttons</h3>
            <div className="tmp-dev-container">
              <Button
                href="/"
                size="small"
                variant="contained"
                color="secondary"
              >
                Small Button
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button
                href="/"
                size="normal"
                variant="contained"
                color="secondary"
              >
                Normal Button
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button
                href="/"
                size="large"
                variant="contained"
                color="secondary"
              >
                Large Button
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button
                href="/"
                size="normal"
                variant="contained"
                color="secondary"
                style={{ fontSize: 24 }}
              >
                Custom Font Button
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button onClick={() => alert("hello")} variant="contained">
                Click to trigger code
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button
                href="/"
                size="normal"
                variant="contained"
                color="secondary"
                disabled
              >
                Normal Button
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button
                href="/"
                size="normal"
                variant="contained"
                color="secondary"
                startIcon={<SaveIcon />}
              >
                Icon Button Start
              </Button>
            </div>

            <div className="tmp-dev-container">
              <Button
                href="/"
                size="normal"
                variant="contained"
                color="secondary"
                endIcon={<SaveIcon />}
              >
                Icon Button End
              </Button>
            </div>

            <div className="tmp-dev-container">
              <h3>Button Group</h3>
              <ButtonGroup variant="contained" size="large">
                <Button href="/" color="primary" startIcon={<SaveIcon />}>
                  Save
                </Button>
                <Button href="/" color="secondary" startIcon={<DeleteIcon />}>
                  Discard
                </Button>
              </ButtonGroup>
            </div>

            <div className="tmp-dev-container">
              <h3>Checkbox Example</h3>
              <CheckboxExample />
            </div>

            <div className="tmp-dev-container">
              <h3>TextField Example</h3>
              <TextField
                variant="outlined"
                color="secondary"
                type="email"
                placeholder="test@email.com"
                label="The Time"
              />
            </div>
          </div>
        </div>
      </Container>
    </ThemeProvider>
  );
};

export default Demos;
