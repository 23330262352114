import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";

const HobbiesCard = () => {
  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        title="Hobbies and Interests"
        subheader="Other things I do"
        avatar={
          <Avatar
            src="/images/icon_hobby.png"
            sx={{ bgcolor: "avatar.main", width: 64, height: 64 }}
          />
        }
      />

      <CardContent>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Bike"
                src="/images/hobby_bike.png"
                sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Bike Riding" />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Cooking"
                src="/images/hobby_cooking.png"
                sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Cooking" />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Hiking"
                src="/images/hobby_hiking.png"
                sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Hiking" />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Running"
                src="/images/hobby_running.png"
                sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Running" />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <Avatar
                alt="Gaming"
                src="/images/hobby_videogame.png"
                sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
              />
            </ListItemAvatar>
            <ListItemText primary="Gaming" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default HobbiesCard;
