import { Box, Stack } from "@mui/material";
import EducationCard from "./component/EducationCard";
import HobbiesCard from "./component/HobbiesCard";
import IntroCard from "./component/IntroCard";
import KeyProjectsCard from "./component/KeyProjectsCard";
import KeySkillsCard from "./component/KeySkillsCard";
import KeySkillsCardMobile from "./component/KeySkillsCardMobile";
import WorkHistoryCard from "./component/WorkHistoryCard";

const Splash = () => {
  return (
    <Box className="splash-page">
      <Stack direction="row"  spacing={0} justifyContent="space-between">
        <Box flex={4} p={1} sx={{ display: { xs: "none", sm: "block" } }}>
          <Box position="sticky" top="83px">
            <IntroCard />
          </Box>
        </Box>

        <Box flex={4} p={1} sx={{ display: { xs: "block", sm: "block" } }}>
          <Box mb={2} sx={{ display: { xs: "block", sm: "none" } }}>
            <Stack spacing={2}>
              <IntroCard />
              <KeySkillsCard />
              <KeyProjectsCard />
              <WorkHistoryCard />
              <EducationCard />
              <HobbiesCard />
            </Stack>
          </Box>

          <Box mb={2} sx={{ display: { xs: "none", sm: "block" } }}>
            <Stack spacing={2}>
              <KeySkillsCardMobile />
              <KeyProjectsCard />
              <WorkHistoryCard />
              <EducationCard />
              <HobbiesCard />
            </Stack>
          </Box>
          
          
          
        </Box>
      </Stack>
    </Box>
  );
};

export default Splash;
