import { Paper, Box } from "@mui/material";

const Prototypes = () => {
  return (
    <Paper variant="outlined">
      <Box p={1}>
        <h2>Prototypes Incoming</h2>
      </Box>
    </Paper>
  );
};

export default Prototypes;
