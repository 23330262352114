import AppBar from "@mui/material/AppBar";
import ToolBar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Typography from "@mui/material/Typography";

function NavBar({ onOpenFunc }) {
  return (
    <AppBar color="primary">
      <ToolBar>
        <IconButton
          onClick={() => {
            onOpenFunc();
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="span"></Typography>
        &nbsp;&nbsp;
        <img alt="logo" src="/images/njadev_title.png" height={40} />
      </ToolBar>
    </AppBar>
  );
}

export default NavBar;
