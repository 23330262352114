import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Grid,
  IconButton,
} from "@mui/material";

import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/EmailOutlined";

const IntroCard = () => {
  return (
    <Card sx={{width: "100%"}}>
      <CardMedia
        component="img"
        image="/images/Profile.png"
        alt="Nathan Aichinger"
      />

      <CardContent>
        <Typography gutterBottom variant="h5" component="span">
          Nathan Aichinger
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Linz, Oberösterreich
        </Typography>
        <Typography variant="body2" color="text.secondary" paddingTop="5px">
          Full-stack web developer, with a priority placed on the analysis of a
          customers needs and providing dedicated solutions to their unique
          business cases.
        </Typography>
      </CardContent>

      <CardActions>
        <Grid container>
          <Grid item xs={4} textAlign="center">
            <IconButton size="large" href="/" target="_blank">
              <EmailIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs={4} textAlign="center">
            <IconButton size="large" href="/" target="_blank">
              <TwitterIcon fontSize="large" />
            </IconButton>
          </Grid>

          <Grid item xs={4} textAlign="center">
            <IconButton size="large" href="/" target="_blank">
              <InstagramIcon fontSize="large" />
            </IconButton>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default IntroCard;
