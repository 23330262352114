import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DesignServicesIcon from "@mui/icons-material/DesignServices";

function MenuDarwer({ isOpen, onCloseFunc }) {
  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={() => {
        onCloseFunc();
      }}
      PaperProps={{
        sx: {
          backgroundColor: "primary.light",
        },
      }}
    >
      <Box p={2} width="250px" textAlign="left" role="presentation">
        <img alt="logo" src="/images/njadev_title.png" height={40} />
      </Box>

      <List>
        <ListItem disablePadding>
          <ListItemButton href="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton href="/proto">
            <ListItemIcon>
              <DesignServicesIcon />
            </ListItemIcon>
            <ListItemText primary="Prototypes" />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}

export default MenuDarwer;
