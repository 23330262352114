import { Component, Fragment } from "react";
import {
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";

class EducationLine extends Component {
  render() {
    var posinfo = this.props.position;

    return (
      <ListItemButton alignItems="flex-start" href={posinfo.url}>
        <ListItemAvatar>
          <Avatar
            alt={posinfo.institution}
            sx={{ bgcolor: "avatar.main", width: 48, height: 48 }}
            src={posinfo.logo}
          ></Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={posinfo.course}
          secondary={
            <Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {posinfo.institution}
              </Typography>
              <br />
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="caption"
                color="text.primary"
              >
                {posinfo.daterange}
              </Typography>
            </Fragment>
          }
        ></ListItemText>
      </ListItemButton>
    );
  }
}

export default EducationLine;
